<template>
    <div>
        <el-col :span="4">
            <el-button type="danger" @click="submitQuit" :disabled="loading">离职</el-button>
        </el-col>
    </div>
</template>

<script>
import { PutModifyUserState } from '@/assets/js/api/userapi'
export default {
    props:{
        userid:{
            type: Number,
            default:0
        },
    },
    data(){
        return{
            loading:false,
            modifyUserState:{
                UserID:0,
                State:1,
            }
        }
    },
    methods:{
        async submitQuit(){
            const confirmResult = await this.$confirm('此操作将改变该用户的身份状态, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).
            catch(err=>err);
            if(confirmResult !== 'confirm'){
                return this.$message.info('已取消操作')
            }
            this.modifyUserState.UserID = this.userid 
            this.loading = true
            var ret = await PutModifyUserState(this.modifyUserState)
            this.loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.$message.success('设置成功')
                this.$emit("ChangeModifyUserState")
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
    }
}
</script>
<style scoped lang="less">
@import url("@/assets/less/projectless/component/personalsetting.less");
</style>