import axios from 'axios';

//获取登陆账号的权限
export async function GetAuthorities(){
    var res = await axios.get('Authority/v1/Authorities')
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return null;
    });
    return res;
}