import axios from 'axios';

//获取角色列表
export async function GetCharacterList(){
    var res = await axios.get('Character/v1/Characters')
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return null;
    });
    return res;
}