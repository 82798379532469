<template>
    <div>
        <el-form ref="resetPasswordFormRef" :model="resetPasswordForm" :rules="resetPasswordFormRules" label-width="80px" v-loading="loading">
            <el-form-item label="账号">
                <!-- <el-input v-model="resetPasswordForm.accountNumber" disabled placeholder="请输入账号,用于以后登陆系统(6~11位字符)" autocomplete="off" 
                oninput="if(value.length>11)value=value.slice(0,11)"
                onkeyup="this.value=this.value.replace(/[^\w]/g,'')"></el-input> -->
                <span class="span_div">{{ resetPasswordForm.accountNumber }}</span> 
            </el-form-item>
            <el-form-item label="密码" prop="password">
                <el-input v-model="resetPasswordForm.password" placeholder="请输入密码(7~18位字符)" show-password minlength="6"
                oninput="if(value.length>18)value=value.slice(0,18)"
                onkeyup="this.value=this.value.replace(/[^\w]/g,'')"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="surepassword">
                <el-input v-model="resetPasswordForm.surepassword" placeholder="请输入密码(7~18位字符)" show-password minlength="6"
                oninput="if(value.length>18)value=value.slice(0,18)"
                onkeyup="this.value=this.value.replace(/[^\w]/g,'')"></el-input>
            </el-form-item>
            <el-col :span="4">
                <el-button type="primary" @click="submitPassword" :disabled="loading">确认</el-button>
            </el-col>
        </el-form>
    </div>
</template>
<script>
import { GetUserAccountInfoByID, PutAccountPassword } from '@/assets/js/api/userapi'
import { GetGUID  } from '@/assets/js/common'
export default {
    props:{
        visible:{
            type: Boolean,
            default:false
        },
        userid:{
            type: Number,
            default:0
        },
    },
    computed:{
        resetPasswordComponentVisible:{
            get: function() {
                return this.visible
            },
            set: function(val) {
                this.$emit('update:visible', val)
            }
        }
    },
    watch:{
        resetPasswordComponentVisible:function (newVisible) {
            if(newVisible){
                if(this.$refs.resetPasswordFormRef)
                {
                    this.$refs.resetPasswordFormRef.resetFields()
                }
                this.getUserInfo()
            }
        },
    },
    data(){
        var checkPassword=(rule,value,callback)=>{
            if(value.length >= 7){
                return callback()
            }
            callback(new Error('密码长度最小7位字符'))
        }
        return{
            resetPasswordForm:{
                surepassword:'',
            },
            resetPasswordFormRules:{
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { validator:checkPassword, trigger: 'blur'}
                ],
                surepassword: [
                    { required: true, message: '请输入确认密码', trigger: 'blur' },
                    { validator:checkPassword, trigger: 'blur'}
                ],
            },
            loading:false,
        }
    },
    methods:{
        isChange(){
            if(typeof(this.resetPasswordForm.password) !== "undefined" && this.resetPasswordForm.password !== null &&  this.resetPasswordForm.password.length !== 0){
                return true
            }
            if(typeof(this.resetPasswordForm.surepassword) !== "undefined" && this.resetPasswordForm.surepassword !== null && this.resetPasswordForm.surepassword.length !== 0){
                return true
            }
            return false
        },
        async getUserInfo(){
            this.loading = true
            var ret = await GetUserAccountInfoByID(this.userid)
            this.loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.resetPasswordForm = ret.data;
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        async submitPassword(){
            this.$refs.resetPasswordFormRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            }
            if(this.resetPasswordForm.password != this.resetPasswordForm.surepassword){
                return this.$message.warning('两次密码不同,请重新确认密码');
            }
            const confirmResult = await this.$confirm('确认重置密码, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).
            catch(err=>err);
            if(confirmResult !== 'confirm'){
                return this.$message.info('已取消操作')
            }
            this.resetPasswordForm.Key = GetGUID();
            let sha512 = require("js-sha512").sha512
            this.resetPasswordForm.password = sha512(this.resetPasswordForm.password  + this.resetPasswordForm.Key)
            this.loading = true
            var ret = await PutAccountPassword(this.resetPasswordForm)
            this.loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.$message.success('重置密码成功')
                if(this.$refs.resetPasswordFormRef)
                {
                    this.$refs.resetPasswordFormRef.resetFields()
                }
            }
            else
            {
                this.$message.error(ret.message);
            }
        }
        
       
    }
}
</script>

<style lang="less" scoped>
@import url("@/assets/less/projectless/component/personalsetting.less");
.span_div{
    color: var(--color-title);
    font-weight: 500;
    display: flex;
}
</style>