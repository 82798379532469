<template>
    <el-container class="home_container_div">
        <el-aside>
            <div class="title_div">
                <span class="title_span_div">SpotRobot</span> 
            </div> 
            <!-- <div class="toggle_button_div" @click="toggleCollapse">|||</div> -->
            <el-menu :unique-opened="true" :collapse="false" 
            :collapse-transition="false" :router="true" :default-active="activePath" v-loading="loading">
                    <el-submenu :index="menuitem.id + ''" v-for="menuitem in authorizationList" :key="menuitem.id">
                        <template slot="title">
                            <i :class="menuitem.icon"></i>
                            <span>{{menuitem.name}}</span>
                        </template>
                        <el-menu-item :index="'/' + subitem.abbreviation" v-for="subitem in menuitem.authorities" 
                        :key="subitem.id" @click="saveNavState('/' + subitem.abbreviation)">
                            <i class="el-icon-menu"></i>
                            <span>{{subitem.name}}</span>
                        </el-menu-item>
                    </el-submenu>
            </el-menu>
        </el-aside>
        
        <el-container>
            <!--头布局-->
            <el-header>
                <div class="header_content_div">
                    <el-tooltip v-if="this.isDayMode" effect="dark" content="夜间模式" placement="top" :enterable="false">
                        <el-button style="margin-right:20px" type="moonmodel" icon="el-icon-moon"  @click="toggleMode"></el-button>
                    </el-tooltip>
                    <el-tooltip v-else effect="light" content="日间模式" placement="top" :enterable="false">
                        <el-button  style="margin-right:20px" type="lightmodel" icon="el-icon-sunny"  @click="toggleMode"></el-button>
                    </el-tooltip>
                    <el-dropdown trigger="click" @command="dropdownChange">
                        <span class="dropdown_link_div">
                            {{this.userInfo.name}}<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item  v-for="(item) in option" :key="item.value" :icon="item.icon" :command="item.value"><span> {{ item.label }}</span></el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </el-header>
            <el-divider></el-divider>
            <el-container>
                <el-main>
                    <el-tabs type="card" v-model="activeTabIndex" closable v-if="openTableTabs.length" @tab-click='tabClick' @tab-remove='tabRemove'>
                        <el-tab-pane :key="item.name" v-for="(item) in openTableTabs" :label="item.label" :name="item.route">
                            <div>
                                <transition>
                                    <keep-alive>
                                        <router-view v-if="item.IsVisible === true"></router-view>
                                    </keep-alive>
                                </transition>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </el-main>
            </el-container>
        </el-container>
        <!-- <el-footer height="2vh">Copyright © 2023 斑点信息科技服务有限公司. All rights reserved. </el-footer> -->
        <UserSettingDialog :visible.sync="userSettingDialogVisible" :userdata="this.userInfo" :morepower="false">
        </UserSettingDialog>
    </el-container>
</template>



<script>
import Vue from 'vue';
import { Avatar,Form, FormItem,Input, Button, Message, Container,Header, Aside, Main, Menu, Submenu, MenuItemGroup, 
MenuItem, Breadcrumb, BreadcrumbItem, Card, Row, Col, Table, TableColumn, Switch, Tooltip, Pagination,Dialog,RadioGroup, 
Radio,Select,Option, MessageBox, Tag, Tree, Cascader, Alert, TabPane, Tabs,Steps, Step,Checkbox, CheckboxGroup, Upload, Timeline, TimelineItem,
 Divider,DatePicker,Descriptions,DescriptionsItem,Footer, Dropdown, DropdownMenu, DropdownItem, Loading, Empty, InputNumber,Rate, Link, Image, Progress, 
 TimePicker, Drawer ,Badge, Calendar,Carousel, CarouselItem }  from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/base.css';
// Vue.use(Statistic)
Vue.use(CarouselItem)
Vue.use(Carousel)
Vue.use(Calendar)
Vue.use(Badge)
Vue.use(Drawer)
Vue.use(TimePicker)
Vue.use(Progress)
Vue.use(Image)
Vue.use(Link)
Vue.use(Rate)
Vue.use(InputNumber)
Vue.use(Empty)
Vue.use(Loading)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Dropdown)
Vue.use(DatePicker)
Vue.use(Avatar)
Vue.use(Divider)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Button)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItemGroup)
Vue.use(MenuItem)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Card)
Vue.use(Row)
Vue.use(Col)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Switch)
Vue.use(Tooltip)
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Select)
Vue.use(Option)
Vue.use(Tag)
Vue.use(Tree)
Vue.use(Cascader)
Vue.use(Alert)
Vue.use(TabPane)
Vue.use(Tabs)
Vue.use(Step)
Vue.use(Steps)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Upload)
Vue.use(Timeline)
Vue.use(TimelineItem)
Vue.use(Descriptions)
Vue.use(DescriptionsItem)
Vue.use(Footer)
Vue.prototype.$ELEMENT = { size: 'mini', zIndex: 3000 };
Vue.prototype.$message= Message
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$alert = MessageBox.alert
import UserSettingDialog from '@/components/UserMamager/Dialog/usersettingdialog.vue';
import { GetAuthorities } from '@/assets/js/api/authorityapi'
import { GetLoginUserInfo } from '@/assets/js/api/userapi'
import { mapState, mapMutations} from 'vuex'
Vue.directive('alterELDialogMarginTop'/*修改elementUI中el-dialog顶部的距离,传入值eg:{marginTop:'5vh'} */, {
  inserted(el, binding) {
    el.firstElementChild.style.marginTop = binding.value.marginTop
  }
})

import variables from '@/assets/less/theme.less';

export default {
    components:{
       UserSettingDialog
    },
    computed: {
        ...mapState('tabRouterMoudle', ['openTableTabs','activeIndex']),
        ...mapState('userInfoMoudle', ['userInfo']),
        ...mapState('authorizationMoudle', ['authorizationList']),
        activeTabIndex: {
            get () { 
                return this.activeIndex 
            },
            set (val) { 
                this.setActiveIndex(val) 
            }
        },
    },
    mounted () {
        this.getthemeMode()
        // 刷新时以当前路由做为tab加入tabs
        // 当前路由不是首页时，添加首页以及另一页到store里，并设置激活状态
        // 当当前路由是首页时，添加首页到store，并设置激活状态
        if(this.$route.path == '/Manager'){
            this.$router.push('/Manager');
        }
        if (this.$route.path !== '/' && this.$route.path !== '/Homepage') {
            this.addTabs({route: '/Homepage' , name: 'Homepage'})
            this.addTabs({route: this.$route.path , name: this.$route.name })
            this.setActiveIndex(this.$route.path)
            // for(var authorization of this.authorizationList) {
            //     for(var item of authorization.authorities){
            //         if(item.abbreviation.toLowerCase() == this.$route.path.substring(1, this.$route.path.length - 1).toLowerCase()){
                            // this.addTabs({route: this.$route.path , name: this.$route.name })
                            // this.setActiveIndex(this.$route.path)
            //             return
            //         }
            //     }
            // }
        } 
        else 
        {
            this.addTabs({route: '/Homepage', name: 'Homepage'})
            this.setActiveIndex('/Homepage')
            this.$router.push('/Manager');
        }
    },
    watch:{
        '$route'(to){
            let flag = false;
            for(let item of this.openTableTabs){
                if(item.name === to.name){
                    this.setActiveIndex(to.path)
                    flag = true;
                    break;
                }
            }
            if(!flag){
                this.addTabs({route: to.path, name: to.name})
                this.setActiveIndex(to.path)
            }
        },
       
    },
    data(){
        return{
            isCollapse: true,
            activePath:'',
            option: [
                { label: '用户设置', value: 0, icon: "el-icon-setting" },
                { label: '退出登录', value: 1, icon: "el-icon-close" },
            ],
            loading: false,
            userSettingDialogVisible: false,
            isDayMode:true,
        }
    },
    created(){
        this.getMenuList()
        this.activePath = this.$cookies.get("ACTIVEPATH","")
        this.getUserInfo()
        this.getthemeMode()
    },
    methods:{
        ...mapMutations('tabRouterMoudle', ['addTabs','deleteTabs', 'setActiveIndex']),
        ...mapMutations('userInfoMoudle', ['updateUserInfo']),
        ...mapMutations('authorizationMoudle', ['updateAuthorizationList']),
        ...mapMutations('themeMoudle', ['setDayMode','setNightMode']),
        async dropdownChange(value){
            if(value == 1){
                const confirmResult = await this.$confirm('确认退出登录?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).
                catch(err=>err);
                if(confirmResult !== 'confirm'){
                    return;
                }
                this.loginOut()
            }
            else if(value == 0){
                this.userSettingDialogVisible = true
            }
        },
        loginOut(){
            //清除cookie
            var cookiearr = this.$cookies.keys()
            for(var i=0; i<cookiearr.length; i++){
                //不清除主题
                if(cookiearr[i] === "THEME"){
                    continue;
                }
                this.$cookies.remove(cookiearr[i]) 
            }
            // sessionStorage.clear();
            this.$message('退出成功');
            this.$router.push('/login');
            this.$cookies.set("SRAUTOLOGIN",false)
        },
        async getMenuList(){
            this.loading = true
            var ret = await GetAuthorities();
            this.loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1){
                this.updateAuthorizationList(ret.data)
            }
            else{
                this.$message.error(ret.message);
            }
        },
        toggleCollapse(){
            this.isCollapse = !this.isCollapse
        },
        saveNavState(activePath){
            // sessionStorage.setItem('activePath',activePath)
            this.$cookies.set("ACTIVEPATH",activePath)
            this.activePath = activePath
        },
        async getUserInfo(){
            var ret = await GetLoginUserInfo()
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1){
                this.updateUserInfo(ret.data)
            }
            else{
                this.$message.error(ret.message);
            }
        },
        tabClick(){
            this.$router.push({path: this.activeIndex});
        },
        tabRemove(targetName){
            //首页不删
            if(targetName == '/Homepage'){
                return
            }
            this.deleteTabs(targetName)
            if (this.activeIndex === targetName) {
                if (this.openTableTabs && this.openTableTabs.length >= 1) {
                    this.setActiveIndex(this.openTableTabs[this.openTableTabs.length-1].route)
                    this.$router.push({path: this.activeIndex});
                } 
                else {
                    this.$router.push({path: '/'});
                }
            }
        },
        getthemeMode(){
            var themevalue= this.$cookies.get("THEME","")
            switch(themevalue)
            {
                case "MOON":
                    this.isDayMode = false
                    break;
                case "LIGHT":
                    this.isDayMode = true
                    break;  
                default:
                    this.isDayMode = true
                    break;  
            }
            this.toggleMode()
        },
        toggleMode(){
            var obj = variables
            
            if(this.isDayMode){
                this.setNightMode(obj)
                this.$cookies.set("THEME", "MOON", "7d")
            }
            else{
                this.setDayMode(obj)
                this.$cookies.set("THEME", "LIGHT", "7d")
            }
            this.isDayMode = !this.isDayMode
        }
    }
}
</script>

<style scoped lang="less">
@import url("@/assets/less/projectless/manager.less");
@import url("@/assets/less/componentless/menu.less");
@import url("@/assets/less/componentless/button_text.less");
@import url("@/assets/less/componentless/divider.less");
@import url("@/assets/less/componentless/tabs.less");
@import url("@/assets/less/componentless/dropdown.less");
</style>

<style lang="less">
@import url("@/assets/less/componentless/body-popper.less");
@import url("@/assets/less/componentless/message.less");
@import url("@/assets/less/componentless/datepicker.less");
@import url("@/assets/less/componentless/cascader.less");
</style>