<template>
    <div>
        <el-form ref="setCharacterFormRef" :model="setCharacterForm" :rules="setCharacterFormRules" label-width="80px"  v-loading="loading">
            <el-form-item label="角色" prop="characterID">
                <el-select class="select_div" width="30" v-model="setCharacterForm.characterID" placeholder="请选择角色" >
                    <el-option v-for="item in characterlist" :key="item.id" :label="item.name" :value="item.id">
                        <span class="span_left_div">{{ item.name }}</span>
                    </el-option>
                </el-select>
            </el-form-item>
            <el-col :span="4">
                <el-button type="primary" @click="submitCharacter" :disabled="loading">确认</el-button>
            </el-col>
        </el-form>
    </div>
</template>
<script>
import { GetUserCharacterByID, PutAccountCharacter } from '@/assets/js/api/userapi'
import { GetCharacterList } from '@/assets/js/api/characterapi'
export default {
    props:{
        visible:{
            type: Boolean,
            default:false
        },
        userid:{
            type: Number,
            default:0
        },
    },
    computed:{
        setCharacterComponentVisible:{
            get: function() {
                return this.visible
            },
            set: function(val) {
                this.$emit('update:visible', val)
            }
        }
    },
    watch:{
        setCharacterComponentVisible:function (newVisible) {
            if(newVisible){
                if(this.$refs.setCharacterFormRef)
                {
                    this.$refs.setCharacterFormRef.resetFields()
                }
                this.getCharacters()
                this.getUserInfo()
            }
        },
    },
    data(){
        return{
            setCharacterForm:{
                characterID:'',
                character:'',
            },
            setCharacterFormRules:{
                characterID: [
                    { required: true, message: '请输入角色', trigger: 'change' }
                ],
            },
            loading:false,
            characterlist:[],
            originalInfo:{},
        }
    },
    methods:{
        async getCharacters(){
            this.loading = true
            var ret = await GetCharacterList()
            this.loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.characterlist = ret.data;
            }
            else
            {
                this.$message.error(ret.message);
            } 
        },
        async submitCharacter(){
            if(!this.isChange()){
                return this.$message.warning('数据没有修改，不需要提交');
            }
            this.$refs.setCharacterFormRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            }
            this.loading = true
            this.setCharacterForm.userID = this.setCharacterForm.id
            var ret = await PutAccountCharacter(this.setCharacterForm)
            this.loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.originalInfo.characterID = this.setCharacterForm.characterID
                this.$message.success('修改用户角色成功')
                this.$emit("ChangeModifyUserCharacter")
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        isChange(){
            if(this.originalInfo.characterID  == this.setCharacterForm.characterID ){
                return false
            }
            else{
                return true
            }
        },
        async getUserInfo(){
            this.loading = true
            var ret = await GetUserCharacterByID(this.userid)
            this.loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.setCharacterForm = ret.data;
                this.originalInfo.characterID = ret.data.characterID
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
    }
}
</script>
<style scoped lang="less">
@import url("@/assets/less/projectless/component/personalsetting.less");
    .select_div{
        display:table
    }
    .span_left_div{
        float: left
    }
</style>