<template>
    <el-dialog title="用户设置" v-alterELDialogMarginTop="{marginTop:'7vh'}" :append-to-body="true" width="50%" 
    :visible.sync="userSettingDialogVisible" :close-on-click-modal="false" @close="closeDialog">
        <div>
            <el-card>
                <el-row slot="header">
                    <el-col :span="2">
                        <span class="dialog-title">{{this.userdata.name}}</span>
                    </el-col>
                </el-row>
                <div>
                    <el-tabs class="dialog-tabs" :before-leave="beforeTabLeave" tab-position="left" v-model="activeName">
                        <el-tab-pane label="个人信息">
                            <PersonalSettingsComponent ref="personalSettingsComponentRef" :visible.sync="personalSettingsComponentVisvible" :userid="this.userdata.id"
                            v-on:ChangeModifyUserInfo="parentChangeModifyUserInfo($event)">
                            </PersonalSettingsComponent>
                        </el-tab-pane>
                        <el-tab-pane label="重置密码">
                            <ResetPasswordComponent ref="resetPasswordComponentRef" :visible.sync="resetPasswordComponentVisvible" :userid="this.userdata.id">
                            </ResetPasswordComponent>
                        </el-tab-pane>
                        <el-tab-pane label="分配角色" v-if="morepower">
                            <SetCharacterComponent ref="setCharacterComponentRef" :visible.sync="setCharacterComponentVisvible" :userid="this.userdata.id"
                            v-on:ChangeModifyUserCharacter="parentChangeModifyUserCharacter()">
                            </SetCharacterComponent>
                        </el-tab-pane>
                        <el-tab-pane label="员工离职" v-if="morepower">
                            <UserQuitComponent :userid="this.userdata.id"
                             v-on:ChangeModifyUserState="parentChangeModifyUserState()">
                            </UserQuitComponent>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </el-card>
        </div>
    </el-dialog>
</template>

<script>
import PersonalSettingsComponent from './Component/personalsettings.vue';
import ResetPasswordComponent from './Component/resetpassword.vue';
import SetCharacterComponent from './Component/setcharacter.vue';
import UserQuitComponent from './Component/userquit.vue';
export default {
    components:{
        PersonalSettingsComponent,
        ResetPasswordComponent,
        SetCharacterComponent,
        UserQuitComponent,
    },
    props:{
        visible:{
            type: Boolean,
            default:false
        },
        morepower:{
            type: Boolean,
            default:true
        },
        userdata:Object,
    },
    computed:{
        userSettingDialogVisible:{
            get: function() {
                return this.visible
            },
            set: function(val) {
                this.$emit('update:visible', val)
            }
        }
    },
    watch:{
        userSettingDialogVisible:function (newVisible) {
            if(newVisible){
                this.activeName = '0'
                this.personalSettingsComponentVisvible = true
                this.resetPasswordComponentVisvible = false
                this.setCharacterComponentVisvible = false
                
            }
        },
    },
    created(){
        this.activeName = '0'
        this.personalSettingsComponentVisvible = true
        this.resetPasswordComponentVisvible = false
        this.setCharacterComponentVisvible = false
    },
    data(){
        return{
            activeName:'',
            personalSettingsComponentVisvible:false,
            resetPasswordComponentVisvible:false,
            setCharacterComponentVisvible:false,
        }
    },
    methods:{
        closeDialog(){
           this.userSettingDialogVisible = false
           this.personalSettingsComponentVisvible = false
           this.resetPasswordComponentVisvible = false
           this.setCharacterComponentVisvible = false
        },
        beforeTabLeave(newActiveName,oldActiveName){
            if(oldActiveName == '0'){
                if(this.$refs.personalSettingsComponentRef.isChange()){
                    return this.$confirm('您有修改的内容没有提交,是否放弃修改?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.personalSettingsComponentVisvible = false
                    }).catch(() => {
                        throw new Error('取消成功！')
                    })
                }
                this.personalSettingsComponentVisvible = false
            }
            else if(oldActiveName == '1')
            {
                if(this.$refs.resetPasswordComponentRef.isChange()){
                    return this.$confirm('您有修改的内容没有提交,是否放弃修改?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.resetPasswordComponentVisvible = false
                    }).catch(() => {
                        throw new Error('取消成功！')
                    })
                }
                this.resetPasswordComponentVisvible = false
            }
            else if(oldActiveName == '2')
            {
                if(this.$refs.setCharacterComponentRef.isChange()){
                    return this.$confirm('您有修改的内容没有提交,是否放弃修改?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.setCharacterComponentVisvible = false
                    }).catch(() => {
                        throw new Error('取消成功！')
                    })
                }
                this.setCharacterComponentVisvible = false
            }
            if(newActiveName == '0')
            {
                this.personalSettingsComponentVisvible = true
                this.resetPasswordComponentVisvible = false
                this.setCharacterComponentVisvible = false
            }
            else if(newActiveName == '1'){
                this.resetPasswordComponentVisvible = true
                this.personalSettingsComponentVisvible = false
                this.setCharacterComponentVisvible = false
            }
            else if(newActiveName == '2')
            {
                this.setCharacterComponentVisvible = true
                this.personalSettingsComponentVisvible = false
                this.resetPasswordComponentVisvible = false
            }
        },
        parentChangeModifyUserInfo(data){
            this.userdata.name = data.name
            this.$emit("ChangeModifyUserInfo", data)
        },
        parentChangeModifyUserCharacter(){
            this.$emit("ChangeModifyUserInfo")
        },   
        parentChangeModifyUserState(){
            this.closeDialog()
            this.$emit("ChangeModifyUserInfo")
        }
    }
}
</script>

<style scoped lang="less">
@import url("@/assets/less/projectless/dialog/user/usersetting.less");
</style>