<template>
    <div class="usersetting-height-div">
        <el-form :model="userInfoForm" :rules="userInfoFormRules" ref="userInfoFormRef" label-width="80px" v-loading="loading">
            <el-alert class="alert-div" title="用户基本信息" type="info" show-icon :closable="false"></el-alert>
            <el-form-item class="form-item-input" label="姓名" prop="name">
                <el-input v-model="userInfoForm.name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="性别" prop="gender">
                <el-radio-group class="form-item-radiogroup" v-model="userInfoForm.gender">
                    <el-radio label="男"></el-radio>
                    <el-radio label="女"></el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="城市">
                <el-col :span="6">
                    <el-cascader 
                    popper-class="pc-sel-area-cascader" 
                    :options="options" 
                    v-model="selectedOptions" 
                    @change="selectedCityHandleChange">
                    </el-cascader>
                </el-col>
            </el-form-item>
            <el-form-item class="form-item-input" label="地址" prop="address">
                <el-input v-model="userInfoForm.address" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item class="form-item-input" label="座机" prop="landline">
                <el-input v-model="userInfoForm.landline" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item class="form-item-input" label="手机" prop="phone">
                <el-input v-model="userInfoForm.phone" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item class="form-item-input" label="身份证" prop="numberCard">
                <el-input minlength="11" v-model="userInfoForm.numberCard" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item class="form-item-input" label="邮箱" prop="email">
                <el-input v-model="userInfoForm.email" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item class="form-item-input" label="微信号" prop="weChatID">
                <el-input v-model="userInfoForm.weChatID" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="生日" prop="birthday">
                <el-date-picker 
                popper-class="pc-date-picker"
                class="date-picker" 
                type="date" 
                placeholder="选择日期"  
                format="yyyy 年 MM 月 dd 日" 
                value-format="M/d/yyyy" 
                v-model="userInfoForm.birthday"></el-date-picker>
            </el-form-item>
            <el-alert class="alert-div" title="紧急联系人信息" type="info" show-icon :closable="false"></el-alert>
            <el-form-item class="form-item-input" label="姓名" prop="emergencyContact">
                <el-input v-model="userInfoForm.emergencyContact" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item class="form-item-input" label="电话" prop="emergencyPhone">
                <el-input v-model="userInfoForm.emergencyPhone" autocomplete="off"></el-input>
            </el-form-item>
            <el-col :span="4">
                <el-button type="primary" @click="submitUser" :disabled="loading">确认</el-button>
            </el-col>
        </el-form>
    </div>
</template>

<script>
import { provinceAndCityData, TextToCode ,CodeToText} from 'element-china-area-data'
import { GetUserSettingInfoByID, PutModifyUserData } from '@/assets/js/api/userapi'
import { validateEmail, validateMobile  } from '@/assets/js/util/common'
import { validateIdent  } from '@/assets/js/util/card'
export default {
    props:{
        visible:{
            type: Boolean,
            default:false
        },
        userid:{
            type: Number,
            default:0
        },
    },
    computed:{
        personalSettingsComponentVisible:{
            get: function() {
                return this.visible
            },
            set: function(val) {
                this.$emit('update:visible', val)
            }
        }
    },
    watch:{
        personalSettingsComponentVisible:function (newVisible) {
            if(newVisible){
                if(this.$refs.userInfoFormRef)
                {
                    this.$refs.userInfoFormRef.resetFields()
                }
                this.getUserInfo()
            }
        }
    },
    data(){
        var checkEmail = (rule,value,callback) => { 
            if(validateEmail(value)){
                return callback()
            }
            callback(new Error('请输入合法邮箱'))
        }
        var checkMobilePhone=(rule,value,callback)=>{
            if(validateMobile(value)){
                return callback()
            }
            callback(new Error('请输入合法手机号码'))
        }
        var checkCard=(rule,value,callback)=>{
            let data = value.toUpperCase()
            if(validateIdent.IdentityCodeValid(data)){
                return callback()
            }
            callback(new Error('请输入合法身份证号码'))
        }
        return{
            userInfoForm:{},
            userInfoFormRules:{
                name: [
                    { required: true, message: '请输入姓名', trigger: 'blur' }
                ],
                gender: [
                    { required: true, message: '请选择性别', trigger: 'change' }
                ],
                province: [
                    { required: true, message: '请选择省份', trigger: 'select' }
                ],
                address: [
                    { required: true, message: '请输入地址', trigger: 'blur' }
                ],
                phone: [
                    { required: true, message: '请输入手机', trigger: 'blur' },
                    {validator:checkMobilePhone, trigger: 'blur'}
                ],
                email: [
                    { required: true, message: '请输入邮箱', trigger: 'blur' },
                    {validator:checkEmail, trigger: 'blur'}

                ],
                numberCard: [
                    { required: true, message: '请输入身份证', trigger: 'blur' },
                    { validator:checkCard, trigger: 'blur'}
                ]
            },
            options:provinceAndCityData,
            selectedOptions:[],
            loading:false,
            originalInfo:{},
        }
    },
    created(){
        this.getUserInfo()
    },
    methods:{
        async getUserInfo(){
            this.loading = true
            var ret = await GetUserSettingInfoByID(this.userid)
            this.loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.userInfoForm = ret.data;
                var arr1 = Object.keys(this.userInfoForm)
                for(var j =0; j< arr1.length; j++)
                {
                    this.originalInfo[arr1[j]] = this.userInfoForm[arr1[j]]
                }
                this.selectedOptions = [TextToCode[this.userInfoForm.province.toString()].code.toString(),TextToCode[this.userInfoForm.province.toString()][this.userInfoForm.city.toString()].code.toString()]
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        isChange(){
            var chang = false
            var arr = Object.keys(this.userInfoForm)
            for(var i =0; i< arr.length; i++)
            {
                if(this.originalInfo[arr[i]] != this.userInfoForm[arr[i]])
                {
                    chang = true
                }
            }
            return chang
        },
        async submitUser(){
            if(!this.isChange()){
                return this.$message.warning('数据没有修改，不需要提交');
            }
            this.$refs.userInfoFormRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            }
            this.loading = true
            var ret = await PutModifyUserData(this.userInfoForm)
            this.loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.$message.success('修改用户信息成功');
                var arr1 = Object.keys(this.userInfoForm)
                for(var j =0; j< arr1.length; j++)
                {
                    this.originalInfo[arr1[j]] = this.userInfoForm[arr1[j]]
                }
                this.$emit("ChangeModifyUserInfo", this.userInfoForm)
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        selectedCityHandleChange(value){
            this.UserInfo.province = CodeToText[value[0]]
            this.UserInfo.city = CodeToText[value[1]]
        },
    }
}
</script>

<style lang="less" scoped>
@import url("@/assets/less/projectless/component/personalsetting.less");
</style>
